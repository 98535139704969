








import { Component, Prop, Vue } from "vue-property-decorator";
import Viewer from "viewerjs";
import Settings from "@/Settings";

@Component
export default class ImageComponent extends Vue {
    @Prop() private imageUrl!: string;
    @Prop() private fileName!: string;
    @Prop({ default: true }) private viewer!: boolean;

    mounted() {
        const element: HTMLImageElement = this.$refs.imageElement as HTMLImageElement;

        if (element && this.viewer) {
            new Viewer(element, {
                title: false,
                navbar: false,
                toolbar: false,
                movable: false
            });
        }
    }

    private getUrl() {
        return this.fileName ? Settings.FilesUrl + this.fileName : this.imageUrl;
    }
}
